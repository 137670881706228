import React from "react"
import { graphql, Link } from "gatsby";
import { Grid, GridCol, Layout, SEO, HoverItem } from "../components";

class WorkPage extends React.Component {
  render() {
    const posts = this.props.data.allMdx.edges;
    const { currentPage, numPages } = this.props.pageContext
    const isFirst = currentPage === 1
    const isLast = currentPage === numPages
    const prevPage = currentPage - 1 === 1 ? "/" : (currentPage - 1).toString()
    const nextPage = (currentPage + 1).toString()

    return (
      <Layout>
        <SEO title="Work"/>
        <Grid>
          {posts.map(item => (
            <GridCol className={'col-4 col-sm-12'}>
              <HoverItem
                img={item.node.frontmatter.images ? item.node.frontmatter.images[0].childImageSharp.fluid : null}
                title={item.node.frontmatter.title}
                link={item.node.fields.slug}
              />
            </GridCol>
          ))}
          <GridCol className={'col-12 ta-c spacing-top'}>
            {!isFirst && (
              <Link to={`/work/${prevPage}`} rel="prev" className={'pr-2'}>
                ← Previous Page
              </Link>
            )}
            {!isLast && (
              <Link to={`/work/${nextPage}`} rel="next">
                Next Page →
              </Link>
            )}
          </GridCol>
        </Grid>
      </Layout>
    )
  }
}

export default WorkPage

export const pageQuery = graphql`
  query workListQuery($skip: Int!, $limit: Int!) {
    allMdx(
      filter: {fileAbsolutePath: {regex: "/work/"}},
      sort: { 
        fields: [frontmatter___date], 
        order: DESC 
      }
      limit: $limit
      skip: $skip
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "DD MMMM, YYYY")
            images {
             childImageSharp {
               fluid(maxWidth: 290, quality: 100) {
                  ...GatsbyImageSharpFluid
               }
             }
            }
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
  }
`